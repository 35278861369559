<template>
    <div class="zidingyi">
        <span><span class="mark">
            <img :src="title==='账号'?require('../assets/sgyuser.png'):require('../assets/sgypassword.png')">
        </span> </span>
        <input :type="type" :placeholder="placeholder" @input="updateVal($event.target.value)">
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    props:{
        title:{
            type: String,
            default: () => '',
        },
        isTrue:{
            type: Boolean,
            default: () => true,
        },
        type:{
            type: String,
            default: () => 'text',
        },
        placeholder:{
            type: String,
            default: () => '',
        },
            
    },
    methods: {
        updateVal: function(val) {
            this.$emit('input', val);  
          }
    },
}
</script>
<style lang="scss" scoped>
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #000 inset !important; // 记住密码后的input背景颜色
    -webkit-text-fill-color: #fff !important; //  记住密码后的input字体颜色
}
input::-webkit-input-placeholder{
    // padding-left:33px !important;
}
 
.zidingyi{
    border: 1px solid #fff;
    border-radius: 10px;
    background: #000;
    width: 315px;
    height: 39px;
    margin: auto;
    margin-bottom: 15px;
    line-height: 39px;
    position: relative;
    .mark{
        display: inline-block;
        width: 18px;
        color: #FF0000;
        font-size: 14px;
        text-align: right;
        img{
            margin: 8px;
        }
    }
    input{
        height: 33px;
        width: 243px;
        position: absolute;
        border: none;
        outline: none;
        background: #000;
        right: 35px;
        top: 2px;
        color: #fff;
        // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
        // border-radius: 8px;
        box-sizing: border-box;
        padding-left: 5px;
    }
}
</style>
