<template>
    <div class="login">
        <div class="title">
            <img style="height:94px" src="../assets/sgy6.png">
            <span class="line"/>
            <span class="txt">全国财务数智化人才培养工程（NFTP）</span>
        </div>
        <div class="content">
            
            <div class="block">
                <div class="title">用户登录</div>
                <div class="go-register"><a href="/register" style="font-size:12px">没有账号，立即注册>></a></div>
                <Cinput title="账号" v-model="account" :placeholder="'请输入您的用户名'" />
                <Cinput title="密码" type="password" v-model="password" :placeholder="'请输入您的密码'" />
                <div class="info">
                    <input type="checkbox" v-model="isRemeber"> 记住我的登录信息
                    <div class="reset"><a href="/reset">忘记密码？</a></div>
                </div>
                <div :class="hasInput?'active':''" class="btn" @click="next">登录</div>
            </div>
        </div>
        
    </div>
</template>
<script>
import Cinput from './userInput2';
export default {
    data() {
        return {
            account:'',
            password:'',
            isRemeber: false,
        }
    },
    computed:{
        hasInput(){
            if(this.account !== '' && this.password !== ''){
                return true;
            }else{
                return false;
            }
        }
    },
    components:{
        Cinput,
    },
    methods: {
        async next(){
            if(!this.hasInput) return false;
            if(this.validateItem()){
                try {
                    const res = await this.$http.post('/api/user/login',{account:this.account,password:this.password});
                    if(Object.hasOwnProperty.call(res,'userinfo')){
                        localStorage.setItem('shouguancloud_token',res.userinfo.token);
                        this.$store.commit('CHANGE_STATE',{userInfo:res.userinfo});
                        this.$toast('登录成功');
                        this.$store.dispatch('UPDATE_INDEXCONFIG',this.$http);
                        const tem = setTimeout(() => {
                            this.$router.push('/');
                            clearTimeout(tem);
                        }, 1500);
                    }
                    console.log(res);
                } catch (error) {
                    this.$toast(error.message||'请求失败');
                }
                
            }
        },
        validateItem(){
            const account = this.account;
            const pasword = this.password;
            // const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if(account===''){
                this.$toast('请输入账号')
                return false;
            }else if(pasword === ''){
                this.$toast('请输入密码')
                return false;
            }
            return true;
        }
    },
}
</script>
<style lang="scss" scoped>
.login{
    width: 100%;
    height: 100%;
    background: url('../assets/loginbg.png');
    background-attachment:fixed;
    background-position:center;
    position: relative;
    .title{
        padding-top: 60px;
        margin: 0 auto 20px auto;
        text-align: center;
        img{
            vertical-align: middle;
        }
        .line{
            display: inline-block;
            vertical-align: middle;
            height: 30px;
            width: 1px;
            margin: 0 30px;
            background: #fff;
        }
        .txt{
            vertical-align: middle;
            font-size: 43px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
}
.content{
    width: 100%;
    height: 100px;
    color: #fff;
    .go-register{
        position: absolute;
        top: 69px;
        right: 55px;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
    }
    .title{
        text-align: center;
        font-size: 20px;
        height: 37px;
        font-weight: bold;
    }
    .subtitle{
        font-size: 18px;
        font-weight: bold;
        padding-left: 20px;
        margin-top: 20px;
    }
}
.block{
    width: 420px;
    height: 358px;
    background: #0D182F;
    border: 1px solid #FFFFFF;
    top: calc(50vh - 150px);
    box-sizing: border-box;
    position: absolute;
    right: 150px;
    .title{
        width:100%;
        font-size: 18px;
        padding: 24px 0 40px 0;
        text-align: center;
        color: #fff;
        font-weight: 600;
    }
}

.info{
    width: 317px;
    margin: auto;
    position: relative;
    margin-bottom: 25px;
    font-size: 14px;
    input{
        border:0px;
        outline: none;
    }
    .reset{
        color:#fff;
        position: absolute;
        right: 2px;
        top: 0px;
        font-size: 14px;
    }
}
.btn{
    width: 317px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color:#fff;
    background: #3698FA;
    border-radius: 8px;
}
.active{
    cursor: pointer;
    background: #3698FA !important;
    color: #fff !important;
}
a, a:link{
    color: #fff !important;
}


</style>
